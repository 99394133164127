import React, { useState } from "react";
import { useRef } from "react";
import SharedVideo from "../../shared/video";

export default function Video(props) {
 


  const videoRef = useRef(0.1);

  const [playing, setPlaying] = useState(true);

  const videoHandler = (control) => {
    if (control === true) {
      // var tracks = videoRef.current.textTracks[0];
      // tracks.mode = 'showing';
      videoRef.current.play();
    } else if (control === false) {
      videoRef.current.pause();
    }
  };

  return (
    <SharedVideo
      video={props.video.video}
      videoRef={videoRef}
      poster={props.video.image}
      subtitles={props.video.subtitles}
      buttontext="Produkt Demo Video"
      buttonGridTemplateAreas={`'. icon text' 'vblue . .'`}
      buttonTextBackgroundColor="black"
      buttonTextColor="white"
      buttonIconColor="yellow"
      playing={playing}
      clickHandler={() => {
        setPlaying(!playing);
        videoHandler(playing);
      }}
    />
  );
}
