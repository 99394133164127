import React from "react";
import DefautLayout from "../../../layouts/default";
import * as ProjectPage from ".";
import { opendata } from "./data/opendata";
import { digifundo } from "./data/digifundo";
import { valido } from "./data/valido";
import { prolysis } from "./data/prolysis";
import { farmspect } from "./data/farmspect";
import { tenderme } from "./data/tenderme";
import { farmpedia } from "./data/farmpedia";
import { sharespace } from "./data/sharespace";
import { blink } from "./data/blink";
import { darkmangos } from "./data/darkmangos";
import { shark } from "./data/shark";
import { smartxplorer } from "./data/smartxplorer";
import { bff } from "./data/bff";
import { unitedteachers } from "./data/unitedteachers";
import { azubiwohnen } from "./data/azubiwohnen";
import { teamhfp } from "./data/teamhfp";
import { teampflege } from "./data/teampflege";
import { teamarzneimittel } from "./data/teamarzneimittel";


export default function Template({ pageContext: { project } }) {
  switch (project.handle) {
    case "azubiwohnen":
      project = azubiwohnen;
      break;
    case "teamhfp":
      project = teamhfp;
      break;
    case "teampflege":
      project = teampflege;
      break;
    case "teamarzneimittel":
      project = teamarzneimittel;
      break;
    case "opendata":
      project = opendata;
      break;
    case "digifundo":
      project = digifundo;
      break;
    case "valido":
      project = valido;
      break;
    case "prolysis":
      project = prolysis;
      break;
    case "farmspect":
      project = farmspect;
      break;
    case "tenderme":
      project = tenderme;
      break;
    case "farmpedia":
      project = farmpedia;
      break;
    case "sharespace":
      project = sharespace;
      break;
    case "blink":
      project = blink;
      break;
    case "darkmangos":
      project = darkmangos
      break;
    case "shark":
        project = shark
        break;
    case "smartxplorer":
        project = smartxplorer
        break;
    case "bff":
        project = bff
        break;
    case "unitedteachers":
        project = unitedteachers
        break;
    default:
      break
  }

  return (
   
    <DefautLayout title="Ergebnisse">
      <div id="result" className={project.handle}>
        <ProjectPage.Header title={project.title} image={project.image} projectLogo={project.projectLogo} ministryLogo={project.ministryLogo} text={project.text} handle={project.handle} />
        <ProjectPage.Challenge challenge={project.challenge} solution={project.solution} />

        {/* <ProjectPage.Video video={project.video} /> */}

        {project.video ? (
          <ProjectPage.Video video={project.video} />
        ) : (
          <p></p>
        )}

        <ProjectPage.Text title="Methodiken &amp; Vorgehen" textLeft={project.methods} textRight={project.procedures} />
        <ProjectPage.Image image={project.processImage} alt={project.handle} />
        <ProjectPage.Text title="Erkenntnisse &amp; Benefits" textLeft={project.findings} textRight={project.benefits} />
        
        {project.links ? (
           <ProjectPage.Links links={project.links} />
        ) : (
          <p></p>
        )}
    

        <ProjectPage.Team projectLogo={project.projectLogo} members={project.members} />
      </div>
    </DefautLayout>
  )
}
